<template>
  <section class="dashboard">
    <div class="grid-margin">
      <div>
        <transition name="slide-up" appear>
          <b-row>
            <b-col cols="12" md="8" class="p-md-0 p-3">
              <h3>Extratos e Antecipações - PAs</h3>
            </b-col>
            <b-col cols="12" md="4" class="p-md-0 p-3">
              <multiselect
                id="inputReceiverSearch"
                v-model="selectedUser"
                :options="items"
                @search-change="searchReceiver"
                tag-placeholder="Selecione"
                placeholder="Busque por CPF/CNPJ ou nome..."
                selectLabel=""
                @input="getPayments"
                deselectLabel=""
                selectedLabel="X"
                label="text"
                track-by="text"
              >
                <template #noOptions> Faça uma busca... </template>
                <template #noResult>
                  Sem resultados! Por favor, refaça sua busca.
                </template>
              </multiselect>
            </b-col>
            <b-col md="12" class="text-right d-flex justify-content-end my-3 mx-md-0 mx-3" v-if="!!selectedUser?.values.workspaceId">
              <PlgAccountBalance :workspace-id="selectedUser?.values.workspaceId"/>
            </b-col>
          </b-row>
        </transition>
      </div>
    </div>

    <template v-if="!!selectedUser?.values.userId && payments.length">
      <transition name="slide-up" appear mode="out-in">
        <div>
          <div class="p-md-0 p-3">
            <h4>Próximos Pagamentos</h4>
          </div>
          <b-row class="mt-4">
            <b-col
              cols="12"
              md="3"
              v-for="payment in payments"
              :key="payment.id"
            >
              <PlgPaymentCard
                class="m-2 text-center"
                :payment="payment"
                :admin-view="true"
                @paid="getPayments"
              />
            </b-col>
          </b-row>
        </div>
      </transition>
    </template>
    <hr />
    <template v-if="!!selectedUser?.values.workspaceId">
      <transition name="slide-up" appear mode="out-in">
        <div>
          <h4 class="p-md-0 p-3">Extrato</h4>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card border rounded">
                <div class="card-body">
                  <plg-bank-statement
                    :workspaceId="selectedUser?.values.workspaceId"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>
  </section>
</template>

<script>
import PlgAccountBalance from "../../components/accounts/PlgAccountBalance.vue";
import PlgBankStatement from "../../components/bank-statement/PlgBankStatement.vue";
import PlgPaymentCard from "../../components/payments/PlgPaymentCard.vue";
import PlgUsersApi from "../../apis/PlgUsersApi.vue";
import PlgPaymentsApi from "../../apis/PlgPaymentsApi.vue";
import moment from "moment";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";

export default {
  name: "dashboard",
  data() {
    return {
      loader: false,
      selectedUser: null,
      payments: [],
      items: [],
      rows: 0,
      tableFields: [],
    };
  },
  mixins: [PlgPaymentsApi, PlgUsersApi],
  components: {
    PlgAccountBalance,
    PlgBankStatement,
    PlgPaymentCard,
    Multiselect,
  },
  methods: {
    async searchReceiver($event) {
      if ($event.length > 2) {
        const payload = {
          filter: { full_search: $event },
        };
        let response = await this.getUsersApi(payload);
        this.items = response.data.users;
        this.items = this.items.map((element) => {
          return {
            text: `${element.name} - ${element.federal_id}`,
            values: {
              workspaceId: element.starkbank_workspace_id,
              userId: element.id,
            },
          };
        });
        this.rows = response.data.users_count;
      }
    },
    async getPayments() {
      let filter = this.getFilterForPaymentsApi();
      filter.receiver_id = this.selectedUser?.values.userId;
      filter.after_date = moment().format("YYYY-MM-DD");
      filter.approved = ["waiting", "antecipation_requested"];
      const payload = {
        filter: filter,
      };
      this.payments = await this.getPaymentsApi(payload);
      this.payments = this.payments.data.payments;
    },
  },
};
</script>

<style scoped>
.item-card {
  border-radius: 30%;
}
.home-button {
  border-radius: 100px;
  height: 8em;
  width: 109.2px;
}
@media (max-width: 440px) {
  .item-card {
    width: 33%;
  }
}
.autocomplete {
  position: absolute;
  z-index: 100;
}
.autocomplete-results {
  padding: 0;
  background-color: #fff;
  margin: 0;
  border: 1px solid #eee;
}
.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}
.autocomplete-result:hover {
  background-color: #004aad;
  color: white;
}
/* classes que simulam a aparência de campo inválido do bootstrap para componentes fora da lib */
.neo-invalid-feedback {
  font-size: 0.875em;
  margin-top: 0.25rem;
  color: #dc3545;
}
.neo-is-invalid {
  border: 1px solid #dc3545;
  border-radius: 0.2rem;
}
</style>
