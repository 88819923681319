<template>
  <div
    :class="{
      payment: !payment.antecipated_amount && !adminView,
    }"
  >
    <b-card
      :title="`Pagamento #${payment.id}`"
      :class="{
        rounded: true,
        antecipated: payment.antecipated_amount,
      }"
      @click="openAntecipateModal"
    >
      <b-card-text>
        <b>Valor:</b> R$
        {{
          formattedAmount.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
        }}
        <br />
        <b>Agendado para:</b> {{ formattedDate }} <br />
        <span class="d-block text-truncate"> {{ payment.description }}</span>
        <hr />
        <b-badge v-if="payment.antecipated_amount" pill variant="info">
          <b>Solicitado:</b> R$
          {{
            (payment.antecipated_amount / 100).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })
          }}
        </b-badge>
        <span v-else>
          <b>Antecipando:</b> R$
          {{
            antecipatedValue.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })
          }}
          <b-button v-if="!adminView && !payment.antecipated_amount" variant="primary" size="sm" class="my-2">Clique aqui e antecipe!</b-button>
        </span>
      </b-card-text>
      <PlgPaymentsAntecipateModal
        ref="AntecipateModal"
        :showModal="showAntecipateModal"
        :antecipatedValue="antecipatedValue"
        :paymentId="payment.id"
        @completed="handleAntecipatedPayment"
        @close="showAntecipateModal = false"
      />
    </b-card>
  </div>
</template>

<script>
import PlgPaymentsAntecipateModal from "../../components/payments/PlgPaymentsAntecipate.vue";
import PlgPaymentsApi from "../../apis/PlgPaymentsApi.vue";
import Toasts from "../Toasts.vue";
import moment from "moment";

export default {
  name: "PlgPaymentCard",

  mixins: [PlgPaymentsApi, Toasts],

  props: {
    adminView: {
      type: Boolean,
      default: false,
    },
    payment: {
      type: Object,
      required: true,
    },
  },

  components: {
    PlgPaymentsAntecipateModal,
  },

  data() {
    return {
      showAntecipateModal: false,
      formattedAmount: this.payment.amount / 100,
      formattedDate: moment(this.payment.scheduled_date, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      ),
      antecipatedValue: 0,
    };
  },

  methods: {
    openAntecipateModal() {
      this.showAntecipateModal =
        this.payment.antecipated_amount || this.adminView ? false : true;
    },
    handleAntecipatedPayment($event) {
      this.showAntecipateModal = false;
      this.showToast($event.data.status, $event.data.message);
      this.$emit("paid");
    },
  },

  async mounted() {
    this.antecipatedValue = await this.calculateAntecipatedPayment(
      this.payment.id
    );
    this.antecipatedValue = this.antecipatedValue.data;
  },
};
</script>

<style scoped>
.payment:hover {
  scale: 1.1;
  transition: all 0.2s ease-out;
  cursor: pointer;
}
.antecipated {
  opacity: 0.75;
}
.badge {
  margin: 0;
  font-size: 0.75rem;
}
</style>
