<template>
  <b-modal
    v-model="showModal"
    title="Antecipar Pagamento"
    hide-header-close
    no-close-on-backdrop
  >
    Você irá receber: R$ {{ antecipatedValue ? antecipatedValue.toLocaleString(
      "pt-BR", { minimumFractionDigits: 2 }
    ) : null }}
    <template #modal-footer>
      <b-button
        size="sm"
        variant="secondary"
        @click="$emit('close')"
      >
        <span>Cancelar</span>
      </b-button>
      <b-button
        size="sm"
        variant="primary"
        @click="payAntecipatedPayment(paymentId)"
      >
        Confirmar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import PlgPaymentsApi from "../../apis/PlgPaymentsApi.vue"

export default {
  name: "PlgPaymentsAntecipateModal",

  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    antecipatedValue: {
      type: Number,
      required: true,
    },
    paymentId: {
      type: Number,
      required: true,
    }
  },

  mixins: [PlgPaymentsApi],

  methods: {
    async payAntecipatedPayment() {
      const updatePayload = {
        new_payment_data: {
          antecipation_status: "requested",
          pay_on_schedule: false,
          antecipated_amount: this.antecipatedValue * 100,
        },
      };
      const updateResponse = await this.updatePaymentApi(this.paymentId, updatePayload);
      if (updateResponse.status === 200) {
        this.$emit("completed", updateResponse);
      }
    }
  },
}
</script>